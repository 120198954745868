import dynamic from "next/dynamic";
import React from "react";

import GenericSectionLoader from "@microsite/components/molecules/GenericSectionLoader";
import LazySectionContainer from "@microsite/components/molecules/LazySectionContainer";

const Content = dynamic(
  () => import("components/organisms/SectionFooter/Content"),
  { loading: () => <GenericSectionLoader /> },
);

const Footer: React.FC = () => {
  return (
    <LazySectionContainer>
      <Content />
    </LazySectionContainer>
  );
};

export default Footer;
